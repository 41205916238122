import React, { useCallback, useEffect, useState } from 'react';
import { Box } from 'grommet';
import EventEmitter from 'utils/event-emitter';
import { withTheme } from 'styled-components';

import queryString from 'query-string';
import Cookies from 'universal-cookie';
import Singleton from 'utils/singleton';

import { DEPARTMENT_EVENTS, TEAM_EVENTS, COMPANY_EVENTS, EMPLOYEE_EVENTS } from 'pages/shiftManagement/constants';
import { PTO_POLICY_EVENTS, TAGS_EVENTS } from 'pto/constants';

import { fetchDepartments } from 'department/controllers/department';
import { fetchTeams } from 'team/controllers/team';
import { fetchTags } from 'tags/controllers/tags';
import { fetchCompanies, getBasicSettings } from 'company/controllers/company';
import { fetchEmployees } from 'employee/controllers/employee';
import {
  createPtoPolicy,
  updatePtoPolicy,
  fetchPtoPolicy,
  getEmployeesList,
  updateEmployeeBalance,
} from 'pto/controllers/pto';

import SectionLoaderAtom from 'atoms/SectionLoader';
import Steps from 'granite/components/Steps';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import BalanceSettingPage from 'pages/addOrEditPtoPolicy/component/BalanceSettingPage';
import PolicyDetailsPage from 'pages/addOrEditPtoPolicy/component/PolicyDetailsPage';
import PolicyProgressbar from 'pages/addOrEditPtoPolicy/component/PolicyProgreesBar';
import AccuralPolicyPage from 'pages/addOrEditPtoPolicy/component/AccuralPolicyPage';
import EmployeeSettingPage from 'pages/addOrEditPtoPolicy/component/EmployeeSettingPage';

const cookies = new Cookies();

let eventEmitter = new EventEmitter();

const getDefaultStep = search => {
  const query = queryString.parse(search);
  if (query.tabId === 'policyDetails' || query.tabId === 'policy-details') return 0;
  if (query.tabId === 'accural') return 1;
  if (query.tabId === 'balance') return 2;
  if (query.tabId === 'employee') return 3;
};

function AddEditPolicyPage(props) {
  const [loader, setLoader] = useState(true);
  const [policyData, setPolicyData] = useState({});
  const [employeesList, setEmployeesList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [defaultTeams, setTeams] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [defaultEmployees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(
    cookies.get('pageSizeEmployeeSelectionTab') !== 'undefined' ? cookies.get('pageSizeEmployeeSelectionTab') : 5,
  );
  const [pageEmployeeSelection, setPageEmployeeSelection] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dateFormat, setDateFormat] = useState('');
  const [dateFormatDisplay, setDateFormatDisplay] = useState('');
  const singleton = new Singleton();
  const {
    location: { search },
  } = props;
  const policyId = props.match.params.id;

  const [activeStep, setActiveStep] = useState(getDefaultStep(search));
  const [fetchState, setFetchState] = useState(null);

  const getPolicyEmployeesList = useCallback(
    fetchData => {
      setLoading(true);
      getEmployeesList(eventEmitter, {
        is_active: true,
        policyId,
        page_size: fetchData?.pageSize || currentPageSize,
        page: fetchData?.page + 1 || currentPage + 1,
      });
    },
    [policyId, currentPageSize, currentPage],
  );

  const onFetchData = useCallback(
    ({ page: nextPage, pageSize, sorted }) => {
      const nextFetch = { page: nextPage, pageSize, sorted };
      const prevFetch = fetchState;
      if (!deepEqual(nextFetch, prevFetch)) {
        setFetchState(nextFetch);
        getPolicyEmployeesList(nextFetch);
      }
    },
    [fetchState, getPolicyEmployeesList],
  );
  const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  const pageChange = useCallback(
    item => {
      cookies.set('employeeSelectionTab', +item + 1, { path: '/' });
      item < 0 ? setCurrentPage(item + 1) : setCurrentPage(item);
    },
    [currentPage],
  );

  const pageSizeChange = useCallback(
    (pageSize, pageIndex) => {
      cookies.set('pageSizeEmployeeSelectionTab', pageSize, { path: '/' });
      cookies.set('employeeSelectionTab', pageIndex, { path: '/' });
      setCurrentPageSize(pageSize);
      setCurrentPage(pageIndex);
    },
    [currentPage, currentPageSize],
  );

  const handleRefresh = useCallback(event => {
    if (event.data === 'REFRESH_ADD_EDIT_POLICY') {
      fetchPtoPolicyAndEmpList();
    } else {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleRefresh);
    return () => {
      window.removeEventListener('message', handleRefresh);
    };
  }, [handleRefresh]);

  useEffect(() => {
    const query = queryString.parse(search);
    if (query.oAuthToken) {
      if (singleton.oAuthToken === null) {
        singleton.oAuthToken = query.oAuthToken;
      }
      if (policyId && policyId !== 'add') {
        fetchCompanies(eventEmitter, {
          paginate: false,
          company_id: +query.company_id,
        });
        fetchPtoPolicyAndEmpList();
      } else {
        setLoader(false);
      }
    }
  }, [search, policyId]);

  const fetchPtoPolicyAndEmpList = useCallback(() => {
    setCurrentPage(0);
    setCurrentPageSize(5);
    setLoader(true);
    fetchPtoPolicy(eventEmitter, {
      is_active: true,
      policyId,
      page_size: 5,
      page: 1,
    });

    getEmployeesList(eventEmitter, {
      is_active: true,
      policyId,
      page_size: 5,
      page: 1,
    });
  }, []);

  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_SUCCESS:
            setDepartments(event.data.departments);
            break;
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Departments are not fetched',
            });
            break;
          case TEAM_EVENTS.GET_TEAM_SUCCESS:
            setTeams(event.data.teams);
            break;
          case TEAM_EVENTS.GET_TEAM_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Teams are not fetched',
            });
            break;

          case TAGS_EVENTS.GET_TAGS_SUCCESS:
            setTags(event.data.tags);
            break;
          case TAGS_EVENTS.GET_TAGS_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Tags are not fetched',
            });
            break;
          case COMPANY_EVENTS.GET_COMPANY_SUCCESS:
            setCompanies(event.data.companies);
            break;
          case COMPANY_EVENTS.GET_COMPANY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Companies are not fetched',
            });
            setCompanies([]);
            setLoader(false);
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_SUCCESS:
            setEmployees(event.data.employees);
            break;
          case EMPLOYEE_EVENTS.GET_EMPLOYEE_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Employees are not fetched',
            });
            break;

          case PTO_POLICY_EVENTS.SHOW_LOADER:
            //  setLoader(true);
            break;
          case PTO_POLICY_EVENTS.HIDE_LOADER:
            //  setLoader(false);
            break;
          case PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS:
            setPolicyData(event.data.policies);
            setDisabled(
              event?.data?.policies?.employees?.length || event?.data?.policies?.is_active === false ? true : false,
            );
            setLoading(false);
            setLoader(false);
            break;
          case PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Pto policies are not fetched',
            });
            setLoader(false);
            break;

          case PTO_POLICY_EVENTS.CREATE_PTO_POLICY_SUCCESS:
            setPolicyData(event.data);
            GenericWindowPostMessage('SUCCESS_TOAST', {
              toastMessage: 'Policy Created successfully !!!',
            });
            event?.data?.policy_type === 'UNPAID_TIME'
              ? GenericWindowPostMessage('EMPLOYEE_TAB', { policyId: event?.data?.id })
              : GenericWindowPostMessage('ACCURAL_TAB', { policyId: event?.data?.id });
            setSubmitting(false);
            break;
          case PTO_POLICY_EVENTS.CREATE_PTO_POLICY_FAILURE:
            setPolicyData([]);
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Policy is not created',
            });
            setSubmitting(false);
            break;

          case COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS:
            setDateFormat(event.data.dateFormat);
            setDateFormatDisplay(event.data.dateFormatDisplay);
            break;
          case COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong !!!',
            });
            break;
          case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS:
            if (event.data.key === 0) {
              if (event?.data?.data?.policy_type === 'UNPAID_TIME') {
                GenericWindowPostMessage('EMPLOYEE_TAB', {
                  policyId: event?.data?.data?.id,
                });
                setActiveStep(3);
              } else {
                GenericWindowPostMessage('ACCURAL_TAB', {
                  policyId: event?.data?.data?.id,
                });
                setActiveStep(1);
              }
            } else if (event.data.key === 1) {
              GenericWindowPostMessage('BALANCE_TAB', {
                policyId: event?.data?.data?.id,
              });
              setActiveStep(2);
            } else if (event.data.key === 2) {
              GenericWindowPostMessage('EMPLOYEE_TAB', {
                policyId: event?.data?.data?.id,
              });
              setActiveStep(3);
            }
            setSubmitting(false);
            if (event.data.key === 0 || event.data.key === 1 || event.data.key === 2) {
              GenericWindowPostMessage('SUCCESS_TOAST', {
                toastMessage: 'Policy Updated successfully !!!',
              });
            } else if (event.data.key === 'add') {
              GenericWindowPostMessage('SUCCESS_TOAST', {
                toastMessage: 'Employees added successfully in policy !!!',
              });
              getPolicyEmployeesList();
            } else if (event.data.key === 'remove') {
              GenericWindowPostMessage('SUCCESS_TOAST', {
                toastMessage: 'Employees removed successfully from policy !!!',
              });
              getPolicyEmployeesList();
            }

            break;
          case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong , Policy is not updated !!!',
            });
            setLoading(false);
            setSubmitting(false);
            break;

          case PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_SUCCESS:
            let pageSize =
              cookies.get('pageSizeEmployeeSelectionTab') !== undefined
                ? cookies.get('pageSizeEmployeeSelectionTab')
                : 5;
            setPageEmployeeSelection(Math.ceil(event.data.count / pageSize));
            setEmployeesList(event.data.results);
            setLoading(false);
            break;
          case PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_FAILURE:
            setEmployeesList([]);
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong !!!',
            });
            setLoading(false);
            break;
          case PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_SUCCESS:
            GenericWindowPostMessage('SUCCESS_TOAST', {
              toastMessage: 'Employee Balance Updated Successfully !!!',
            });
            setLoading(false);
            break;
          case PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event?.data?.errorMessage || 'Something went wrong, Employee balance not updated !!!',
            });

            setEmployeesList(oldData =>
              oldData.map((oldValue, index) => {
                if (oldValue?.employee_id === event?.data?.prvData?.employee_id) {
                  return {
                    ...oldValue,
                    balance: event?.data?.prvData?.balance,
                  };
                } else {
                  return oldValue;
                }
              }),
            );
            setTimeout(() => setLoading(false), 100);
            break;
          default:
        }
      });
    }
    return () => subscription?.unsubscribe();
  }, []);

  useEffect(() => {
    if (companies.length > 0) {
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      getBasicSettings(eventEmitter);
      fetchDepartments(eventEmitter, {
        paginate: false,
        get_active: true,
        get_active_teams: true,
        is_active: true,
        company_id: companiesId,
      });

      fetchTeams(eventEmitter, {
        paginate: false,
        company_id: companiesId,
        is_active: true,
      });

      fetchTags(eventEmitter, {
        company_id: companiesId,
        tag_type: 'JOB',
      });

      fetchEmployees(eventEmitter, {
        company_id: companiesId,
        is_active: true,
        paginate: false,
      });
    }
  }, [companies]);

  const onDepartmentSelect = useCallback(
    data => {
      const deptIds = data.departments && data.departments.length ? data.departments.map(i => i.id) : [];
      const teamIds = data.selectedTeams && data.selectedTeams.length ? data.selectedTeams.map(i => i.id) : [];
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      fetchTeams(eventEmitter, {
        paginate: false,
        company_id: companiesId,
        is_active: true,
        department_ids: JSON.stringify(deptIds),
      });

      fetchEmployees(eventEmitter, {
        company_id: companiesId,
        is_active: true,
        paginate: false,
        department_ids: JSON.stringify(deptIds),
        team_ids: JSON.stringify(teamIds),
      });
    },
    [departments, defaultTeams],
  );

  const onTeamSelect = useCallback(
    data => {
      const teamIds = data.teams && data.teams.length ? data.teams.map(i => i.id) : [];
      const departmentIds =
        data.selectedDepartments && data.selectedDepartments.length
          ? data.selectedDepartments.map(item => item.id)
          : [];
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      fetchEmployees(eventEmitter, {
        company_id: companiesId,
        is_active: true,
        paginate: false,
        team_ids: JSON.stringify(teamIds),
        department_ids: JSON.stringify(departmentIds),
      });
    },
    [defaultTeams],
  );

  const handleSubmit = useCallback(
    (value, rowData, rowIndex) => {
      const parsedValue = parseFloat(parseFloat(value).toFixed(2));
      const employeeBalance = parseFloat(parseFloat(rowData?.employee_balance ?? 0).toFixed(2));
      if (parsedValue !== employeeBalance) {
        if (value === '') {
          GenericWindowPostMessage('FAILURE_TOAST', {
            toastMessage: 'Employee balance can not be empty !!!',
          });
          return;
        }
        if (+value < 0) {
          GenericWindowPostMessage('FAILURE_TOAST', {
            toastMessage: 'Employee balance can not be negative !!!',
          });
          return;
        }
        setLoading(true);
        updateInputBalance(parsedValue, rowIndex);

        const obj = {
          employee_id: rowData.employee_id,
          policy_id: parseInt(policyId),
          new_balance: parsedValue,
        };

        updateEmployeeBalance(eventEmitter, {
          params: obj,
          data: rowData,
        });
      }
    },
    [policyId],
  );

  const updateInputBalance = useCallback((value, rowIndex) => {
    const parsedValue = parseFloat(parseFloat(value).toFixed(2));
    setEmployeesList(oldData =>
      oldData.map((oldValue, index) => {
        if (index === rowIndex) {
          return {
            ...oldValue,
            balance: parsedValue,
          };
        } else {
          return oldValue;
        }
      }),
    );
  }, []);

  const getSteps = () => {
    return [
      {
        title: 'Policy Details',
        content: (
          <PolicyDetailsPage
            createPtoPolicy={createPtoPolicy}
            updatePtoPolicy={updatePtoPolicy}
            eventEmitter={eventEmitter}
            submitting={submitting}
            setSubmitting={setSubmitting}
            disabled={disabled}
            setActiveStep={setActiveStep}
            initialValues={{
              policy_name: policyData?.policy_name || '',
              policy_type: policyData?.policy_type || '',
              code: policyData?.code || '',
              id: policyData?.id,
            }}
          />
        ),
      },
      {
        title: 'Accural Setting',
        content: (
          <AccuralPolicyPage
            data={policyData}
            updatePtoPolicy={updatePtoPolicy}
            eventEmitter={eventEmitter}
            setActiveStep={setActiveStep}
            dateFormat={dateFormat}
            dateFormatDisplay={dateFormatDisplay}
            submitting={submitting}
            setSubmitting={setSubmitting}
            disabled={disabled}
          />
        ),
      },
      {
        title: 'Balance Setting',
        content: (
          <BalanceSettingPage
            data={policyData}
            updatePtoPolicy={updatePtoPolicy}
            eventEmitter={eventEmitter}
            setActiveStep={setActiveStep}
            submitting={submitting}
            setSubmitting={setSubmitting}
            disabled={disabled}
          />
        ),
      },
      {
        title: 'Employee Selection',
        content: (
          <EmployeeSettingPage
            data={policyData}
            employeesList={employeesList.length ? employeesList : []}
            departments={departments}
            defaultEmployees={defaultEmployees}
            defaultTeams={defaultTeams}
            onTeamSelect={onTeamSelect}
            onDepartmentSelect={onDepartmentSelect}
            updatePtoPolicy={updatePtoPolicy}
            eventEmitter={eventEmitter}
            setActiveStep={setActiveStep}
            pageChange={pageChange}
            pageSizeChange={pageSizeChange}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            pageEmployeeSelection={pageEmployeeSelection}
            loading={loading}
            setLoading={setLoading}
            policyId={policyId}
            tags={tags}
            onFetchData={onFetchData}
            submitting={submitting}
            setSubmitting={setSubmitting}
            handleSubmit={handleSubmit}
            disabled={disabled}
          />
        ),
      },
    ];
  };

  return loader ? (
    <SectionLoaderAtom active />
  ) : (
    <Box background={'light-1'} pad="large" margin={{ left: 'medium', right: 'large', top: 'medium' }} flex="grow">
      <Box>
        <Box pad={{ left: 'xlarge', right: 'xlarge' }}>
          <PolicyProgressbar
            data={policyData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            policyId={policyId}
          />
        </Box>
        <Box pad={{ left: 'xlarge', right: 'xlarge' }}>
          <Steps margin="xsmall" activeStep={activeStep} steps={getSteps()} navigationVisible={false} async />
        </Box>
      </Box>
    </Box>
  );
}

export default withTheme(AddEditPolicyPage);
